<template>
	<S2SCard title="Add Connection">
		<v-text-field slot="toolbar-content" label="Filter Connections" v-model="searchVal" hide-details></v-text-field>
		<v-flex xs12 md3 sm4 mr-2>
			<v-select :items="types" v-model="pagination.filters.type" item-value="id" item-text="name" label="Organisation Types" clearable></v-select>
		</v-flex>
		<v-data-table :headers="headers" :items="possibleCustomers" :loading="loading" :search="searchVal" :options.sync="pagination" class="elevation-1">
			<template v-slot:item.addCustomer="{ item }">
				<v-btn class="mr-0" color="accent" @click="onCreate(item.id)">Add</v-btn>
			</template>
		</v-data-table>
	</S2SCard>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils/";
import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "Connections",

	computed: {
		possibleCustomers: function() {
			return this.$store.getters["organisations/connections/possibleCustomers"];
		},
		totalItems: function() {
			return this.$store.state.organisations.connections.totalItems;
		},
		loading: function() {
			return this.$store.state.organisations.connections.loading;
		}
	},

	watch: {
		pagination: {
			deep: true,
			immediate: true,
			handler(paging, oldVal) {
				if (oldVal != null) {
					this.$store.dispatch("organisations/connections/fetchPossibleConnections", {
						page: paging.page,
						pagesize: paging.itemsPerPage,
						organisationType: paging.filters.type,
						search: paging.filters.search
					});
				}
			}
		},
		searchVal: {
			deep: true,
			handler(val) {
				const $this = this;
				setTimeout(function() {
					$this.$set(this.pagination.filters, "search", searchVal);
				}, 500);
			}
		}
	},

	data: function() {
		return {
			Utils: Utils,
			editModel: {},
			searchVal: "",
			types: [],
			headers: [
				{ text: "Customer Code", value: "customerCode" },
				{ text: "Customer Name", value: "customerName" },
				{ text: "Types", value: "types" },
				{ text: "Contact Name", value: "contactName" },
				{ text: "Contact Number", value: "contactNumber" },
				{ text: "Add Customer", value: "addCustomer" }
			],
			pagination: {
				page: 1,
				itemsPerPage: 10, // -1 for All
				filters: {}
			}
		};
	},

	mounted: async function() {
		const response = await LookupsApi.fetchOrgTypes();
		this.types = response.data.results;
	},

	methods: {
		async onCreate(id) {
			const success = await this.$store.dispatch("organisations/connections/createNewConnection", id);
			if (success)
				this.$store.dispatch("organisations/connections/fetchPossibleConnections", {
					page: this.pagination.page,
					pagesize: this.pagination.itemsPerPage,
					organisationType: this.pagination.filters.type,
					search: this.pagination.filters.search
				});
		},
		formatColumn(header, item) {
			return Utils.formatColumn(header, item);
		}
	}
});
</script>
